@import "./variables.scss";

$theme-colors: (
    "primary": $primary
);

$navbar-padding-x: 1rem;
$navbar-padding-y: 1rem;

@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: 'Alexandria';
    font-style: normal;
    font-display: swap;
    font-weight: 100 900;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/alexandria:vf@latest/latin-wght-normal.woff2) format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Handwriting';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/permanent-marker@latest/latin-400-normal.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
    --bs-nav-link-color: #ffffff8c;
    --bs-nav-link-hover-color: #ffffffbf;
}

html,
* {
    font-family: "Alexandria", sans-serif;
    overflow-x: hidden;
}

h1,
h2,
p,
span {
    overflow-y: hidden;
}

.bg-primary {
    color: $light;
}

.bg-light {
    background-color: $light;
}

.app-store-graphic {
    height: 2.5rem;
}

h1 {
    text-transform: uppercase;
}

span.highlight {
    color: $highlight;
    font-family: "Handwriting";
    //text-transform: lowercase;
}

.text-highlight {
    color: $highlight;
}

.text-light {
    color: $light;
}

.too-fresh-logo {
    font-size: var(--bs-nav-link-font-size);
    font-weight: bold;
    line-height: 1;
}

.too-fresh-logo img {
    height: 2.5rem;
    margin-right: 10px;
}

.btn.btn-light {
    background-color: $light;
    color: $primary;
    border: var(--bs-border-width) solid $primary;
}

.btn.btn-light:active, .btn.btn-light:hover {
    background-color: $primary;
    color: $light;
    border: var(--bs-border-width) solid $light;
}

.btn.btn-primary {
    background-color: $primary;
    color: $light;
    border: var(--bs-border-width) solid $light;
}

.btn.btn-primary:active, .btn.btn-primary:hover {
    background-color: $light;
    color: $primary;
    border: var(--bs-border-width) solid $primary;
}

.carousel-control-prev {
    justify-content: start;
}

.carousel-control-next {
    justify-content: end;
}

.carousel-inner {
    padding: 0 30px;
}

.carousel-indicators {
    padding-top: 30px;
}

.strikethrough {
    text-decoration: line-through;
}

// Iubenda
.iub__us-widget {
    display: none !important;
}

@keyframes bounce {
    40% {
        transform: translateY(0)
    }

    55% {
        transform: translateY(80%)
    }

    65% {
        transform: translateY(60%)
    }

    75% {
        transform: translateY(73.33%)
    }

    80% {
        transform: translateY(66.66%)
    }

    90% {
        transform: translateY(0)
    }
}